pre {
  background-color: white;
  border-style: solid;
  border-width: 1px;
  padding: 2%;
}

.App {
  text-align: center;
  background-color: #058aff1b;
  color: black;
}

/* .App-main {
  text-align: left;
  background-color: #ffffff;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
} 

.App-blog {
  padding: 2%;
  background-color: #ffffff;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #000000;
  border-bottom: "1px solid black";
}*/

/* p.solid {
  border-style: solid;
  border-width: 1px;
  padding-top: 2%;
  padding-right: 2%;
  padding-bottom: 2%;
  padding-left: 2%;
  text-align: justify;
  text-justify: inter-word;
} */

/* 
.App-link {
  color: #000000;
}
.nav-link {
  color: #2f5597;
}

a {
  color: #000000;
}

a:hover {
  color: #000000 !important;
}

.SCPlink {
  color: #000000;
}
.SCPlinkW {
  color: #ffffff;
} */
